<template>
    <div class="mediaLinks-container">
        <div class="addRounds-title">
            <div class="back-router" @click="goPage">
                <i class="el-icon-back"></i>
                <div>Back</div>
            </div>
            <div class="addRounds-title-name">Media Links</div>
        </div>
        <div class="mediaLinks-content">
            <div class="item-link">
                <img src="../../assets/images/seriesUrl1.png" class="seriesUrl">
                <input type="text" class="input-class" placeholder="Please enter twitter" v-model="getseries.twitter">
            </div>
            <div class="item-link">
                <img src="../../assets/images/seriesUrl2.png" class="seriesUrl">
                <input type="text" class="input-class" placeholder="Please enter discord" v-model="getseries.discord">
            </div>
            <div class="item-link">
                <img src="../../assets/images/seriesUrl3.png" class="seriesUrl">
                <input type="text" class="input-class" placeholder="Please enter medium" v-model="getseries.medium">
            </div>
            <div class="item-links">
                <img src="../../assets/images/seriesUrl4.png" class="seriesUrl">
                <input type="text" class="input-class" placeholder="Please enter reddit" v-model="getseries.reddit">
            </div>
        </div>
        <div class="mintCreate_button">
            <button @click="mintHandle">
                <span>Confirm</span>
            </button>
        </div>
    </div>
</template>
<script>
const wallet = require('../../web3/common/wallet.js');
export default {
    data() {
        return {
            baseUrlData: '',
            getseries: {
                twitter: '',
                discord: '',
                medium: '',
                reddit: '',
            },
            admin: '',
        };
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },

    },
    methods: {
        goPage() {
            this.$router.go(-1)
        },
        mintHandle() {
            this.uploadtextRequire()
        },
        uploadtextRequire() {
            if (this.mapState.chainId) {
                //obtain wallet address
                wallet.getWalletInfo().then((info) => {
                    this.admin = info.address.toLowerCase();
                    this.network = info.network;
                    this.queryListData()
                })
            }
        },
        queryListData() {
            let params = {
                admin: this.admin,
                seriesid: this.$route.query.id,
                network: this.network,
                twitter: this.getseries.twitter,
                discord: this.getseries.discord,
                medium: this.getseries.medium,
                reddit: this.getseries.reddit,
                tokenaddress: this.$route.query.tokenAddress
            }
            this.$axios.post(this.baseUrlData + this.$api.post_updateserires, params).then(() => {
                setTimeout(() => {
                    this.$router.go(-1)
                }, 1500)
                this.$message({
                    message: 'Submitted successfully',
                    type: 'success'
                });
                this.clearForm()
            })
        },
        clearForm() {
            this.getseries.twitter = ''
            this.getseries.discord = ''
            this.getseries.medium = ''
            this.getseries.reddit = ''
        },
        queryGetseries() {
            if (this.mapState.chainId) {
                //obtain wallet address
                wallet.getWalletInfo().then((info) => {
                    let params = {
                        admin: info.address.toLowerCase(),
                        seriesid: this.$route.query.id,
                        network: info.network,
                        tokenaddress: this.$route.query.tokenAddress
                    }
                    this.$axios.get(this.baseUrlData + this.$api.post_getseries, params).then(res => {
                        this.getseries = res.result
                    })
                })
            }
        },
    },
    created() {
        this.baseUrlData = localStorage.getItem('baseUrl')
        this.queryGetseries()
    },
};
</script>
<style scoped lang="scss" src="../../assets/css/mediaLinks.scss">
</style>